import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import colors from './colors';

// View options here: https://material-ui.com/customization/default-theme/#default-theme
const theme = createMuiTheme({
  palette: {
    primary: {
      main: colors.primary,
      light: colors.primaryLight,
    },
    background: {
      default: '#fff',
    },
  },
  typography: {
    fontFamily: '"Montserrat", sans-serif',
    h1: {
      fontSize: '3rem',
      fontWeight: 350,
      color: colors.headingText,
    },
    h2: {
      fontSize: '2rem',
      color: colors.headingText,
    },
    h3: {
      fontSize: '1.5rem',
      fontWeight: 600,
      color: colors.headingText,
    },
    h4: {
      color: colors.subHeadingText,
    },
    h5: {
      color: colors.subHeadingText,
    },
    h6: {
      color: colors.subHeadingText,
    },
    subtitle1: {
      color: colors.subHeadingText,
    },
    body1: {
      color: colors.subHeadingText,
      lineHeight: '1.75rem',
    },
  },
  overrides: {
    MuiPaper: {
      root: {
        color: colors.subHeadingText,
      },
    },
    MuiButton: {
      root: {
        borderRadius: 20,
        textTransform: 'none',
        boxShadow: 'none',
        color: 'white',
      },
      contained: {
        boxShadow: 'none',
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 32,
        paddingRight: 32,
      },
      containedPrimary: {
        color: '#fff',
      },
    },
    MuiFab: {
      primary: {
        color: '#fff',
      },
    },
  },
});

export default responsiveFontSizes(theme);
