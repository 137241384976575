import { Link, makeStyles, Typography } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { graphql, useStaticQuery } from 'gatsby';
import Image from 'gatsby-image';
import React from 'react';
import ScrollToTop from 'react-scroll-up';
import Sticky from 'react-stickynode';
import materialTheme from '../theme/material-theme';
import FacebookLogoButton from './FacebookLogoButton';
import Footer from './Footer/Footer';
import Navbar from './Navbar/Navbar';
import ResetCss from './resetCSS';
import ScrollUpButton from './ScrollUpButton/ScrollUpButton';

type LayoutProps = {
  children: React.ReactNode;
};

const useStyles = makeStyles(() => ({
  certifiedImage: { width: '150px', height: '150px', display: 'block', marginLeft: 'auto', marginRight: 'auto' },
}));

const Layout: React.FunctionComponent<LayoutProps> = ({ children }) => {
  const data = useStaticQuery(graphql`
    query {
      avatar: file(absolutePath: { regex: "/bsc-logo.png/" }) {
        childImageSharp {
          fluid(maxWidth: 150, maxHeight: 150) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      site {
        siteMetadata {
          author
          about
        }
      }
    }
  `);

  const classes = useStyles();
  return (
    <ThemeProvider theme={materialTheme}>
      <>
        <ResetCss />
        <Sticky top={0} innerZ={9999} activeClass="nav-sticky">
          <Navbar />
        </Sticky>

        {children}

        {/*<Newsletter />*/}
        <Footer>
          <Typography>Copyright &copy; {new Date().getFullYear()}</Typography>
          <Typography>Sweet Dreams Jellybean</Typography>
          <FacebookLogoButton />
          <Image className={classes.certifiedImage} fluid={data.avatar.childImageSharp.fluid} alt="Sleep consultant certification logo" />
          <Typography>
            <Link href="https://drive.google.com/file/d/104oBXeCOAkxfjh-0HN2puC9-wetYrAd7/view" target="_blank">
              Privacy Policy
            </Link>
          </Typography>
          <Typography>
            <Link href="https://drive.google.com/file/d/1K8Ef8P0j4mrYZQRDML7GiEipmq6WDIsO/view" target="_blank">
              Terms and Conditions
            </Link>
          </Typography>
        </Footer>
        <ScrollToTop showUnder={300} duration={700} easing="easeInOutCubic" style={{ bottom: 30, right: 20 }}>
          <ScrollUpButton />
        </ScrollToTop>
      </>
    </ThemeProvider>
  );
};

export default Layout;
