import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { IoLogoFacebook } from 'react-icons/io';
import colors from '../theme/colors';
import cx from 'classnames';

const useStyles = makeStyles(() => ({
  facebookLogo: {
    color: colors.headingText,
  },
  iconButtonLarge: {
    // padding: 0,
    marginLeft: -20,
  },
  facebookLogoLarge: {
    fontSize: '4rem',
  },
}));

type FacebookLogoButtonProps = { large?: boolean };
const FacebookLogoButton = ({ large }: FacebookLogoButtonProps) => {
  const classes = useStyles();

  return (
    <>
      <IconButton className={cx({ [classes.iconButtonLarge]: large })} onClick={() => window.open('https://www.facebook.com/sweetdreamsjellybean/')}>
        <IoLogoFacebook className={cx(classes.facebookLogo, { [classes.facebookLogoLarge]: large })} />
      </IconButton>
    </>
  );
};

export default FacebookLogoButton;
