const colors = {
  transparent: 'transparent', // 0
  black: '#292929', // 1
  white: '#ffffff', // 2
  textColor: '#292929', // 3
  lightTextColor: '#757575', // 4
  inactiveField: '#F7F7F7', // 5
  inactiveColor: '#767676', // 6
  inactiveBG: '#e6e6e6', // 7
  borderColor: '#DBDBDB', // 8
  lightBorderColor: '#ededed', //9
  primary: '#38BEBC', // 11
  primaryLight: '#CAE9E6', // 10
  headingText:'#38BEBC',
  subHeadingText: '#888888',
  text: '#DCDBD6'
};

export default colors;
