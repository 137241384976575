import { Fab } from '@material-ui/core';
import { ArrowUpward as UpIcon } from '@material-ui/icons';
import React from 'react';

interface ScrollUpButtonProps {}

const ScrollUpButton: React.FunctionComponent<ScrollUpButtonProps> = (props) => {
  return (
    <Fab color="primary">
      <UpIcon />
    </Fab>
  );
};

export default ScrollUpButton;
